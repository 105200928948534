import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { ErrorBoundary } from '@sentry/react';
import { useActivity } from '@stackflow/react';
import { rem } from 'polished';
import React, { Suspense, lazy, useRef } from 'react';

import useInViewportOnce from '@/hooks/useInViewportOnce';
import { openKarrotMap } from '@/utils/url';

const LazyKarrotMap = lazy(() => import(/* webpackChunkName: "karrot_map" */ './KarrotMap'));

const FallbackButton = ({ onClick }: { onClick?: () => void }) => {
  return <BoxButton onClick={onClick}>지도에서 위치보기</BoxButton>;
};

type Props = {
  address?: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  name?: string;
  onClick?: () => void;
};

const StaticKarrotMap: React.FC<Props> = ({ coordinates, name, address, onClick }) => {
  const containerRef = useRef(null);
  const { isActive } = useActivity();
  const inViewportOnce = useInViewportOnce(containerRef, {
    threshold: 0.1,
  });

  const handleClick = () => {
    onClick?.();
    openKarrotMap({ coordinates, name, address });
  };

  return (
    <ErrorBoundary fallback={<FallbackButton onClick={handleClick} />}>
      <MapContainer onClick={handleClick} ref={containerRef}>
        {inViewportOnce && isActive && (
          <Suspense>
            <LazyKarrotMap coordinates={coordinates} />
          </Suspense>
        )}
      </MapContainer>
    </ErrorBoundary>
  );
};

const MapContainer = styled.div`
  width: 100%;
  height: calc((100vw - ${rem(32)}) * 0.6);
  border: 1px solid ${vars.$scale.color.grayAlpha50};
  border-radius: ${rem(6)};
  background-color: ${vars.$scale.color.gray100};
  & canvas {
    border-radius: ${rem(6)};
  }
`;

export default StaticKarrotMap;
