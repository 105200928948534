import { graphql } from 'react-relay';

import { useFetchArticleQuery } from '@/__generated__/useFetchArticleQuery.graphql';
import useFetchQuery from '@/hooks/useFetchQuery';

const useFetchArticle = () => {
  return useFetchQuery<useFetchArticleQuery>(graphql`
    query useFetchArticleQuery($id: ID!) {
      article(id: $id) {
        article {
          id
          price
          content
          driveDistance
          user {
            id
          }
          images {
            type
            images {
              id
              url
            }
          }
          chatChannelInfo {
            error
            targetUri
          }
          carData {
            carNo
            carType
            modelYear
            carRegistrationYear
            carRegistrationMonth
            fuelType
            gear
            imgUrl
            options
            displacement
            autoupModel {
              company {
                id
                name
              }
              series {
                id
                name
              }
              subseries {
                id
                name
                imgUrl
              }
              grade {
                id
                name
              }
              subgrade {
                id
                name
              }
            }
          }
          leaseInfo {
            monthlyFee
            contractCompany {
              id
              name
            }
            leaseType
            contractStartedAt
            contractEndedAt
            residualValue
            acquisitionPrice
            postContractOption
            deposit
            scheduleTableImg {
              id
              url
            }
            extraIncludedInMonthlyFee {
              acquisitionTax
              insurance
              carMaintenance
            }
          }
          rentalInfo {
            monthlyFee
            contractCompany {
              id
              name
            }
            contractStartedAt
            contractEndedAt
            residualValue
            acquisitionPrice
            postContractOption
            deposit
            scheduleTableImg {
              id
              url
            }
            extraIncludedInMonthlyFee {
              carTax
              insurance
              carMaintenance
            }
          }
        }
      }
    }
  `);
};

export default useFetchArticle;
