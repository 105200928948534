import { TextButton } from '@daangn/sprout-components-button';
import { Slider } from '@daangn/sprout-components-slider';
import styled from '@emotion/styled';
import { useActivity } from '@stackflow/react';
import { rem } from 'polished';
import React, { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { DistanceFilter_region$key } from '@/__generated__/DistanceFilter_region.graphql';
import useTrack from '@/hooks/useTrack';
import useUpdateFilter from '@/hooks/useUpdateFilter';
import useUser from '@/hooks/useUser';
import { useFlow, useStepFlow } from '@/stackflow';

import FilterButtonFooter from './FilterButtonFooter';
import {
  defaultFilterState,
  useFilterContext,
  useFilterUpdaterContext,
} from './FilterContextProvider';

const markers = [
  {
    value: 1,
    label: '내 동네',
    align: 'start' as const,
  },
  {
    value: 2,
    label: '',
  },
  {
    value: 3,
    label: '',
  },
  {
    value: 4,
    label: '',
  },
  {
    value: 5,
    label: '먼 동네',
    align: 'end' as const,
  },
];

const regionRangeList = markers.map((marker) => marker.value);

type Props = {
  regionRef?: DistanceFilter_region$key | null;
};

const DistanceFilter: React.FC<React.PropsWithChildren<Props>> = ({ regionRef }) => {
  const activity = useActivity();
  const { stepPop } = useStepFlow(activity.name as 'main' | 'search' | 'search_result');
  const { push } = useFlow();
  const { region } = useUser();
  const { trackWithActivityName } = useTrack();
  const {
    openedFilter,
    filter: { regionRange: prevRegionRange },
    isPending,
  } = useFilterContext();
  const { updateOpenedFilter, startTransition } = useFilterUpdaterContext();
  const updateFilter = useUpdateFilter();
  const [regionRange, setRegionRange] = useState<null | number>(prevRegionRange);
  const regionName = region?.name3 ?? '';
  const { numOfNeighborhoods } = useFragment(
    graphql`
      fragment DistanceFilter_region on Region {
        numOfNeighborhoods
      }
    `,
    regionRef ?? null
  ) ?? { numOfNeighborhoods: [0, 0, 0, 0, 0, 0] };

  const handleClickSave = () => {
    updateOpenedFilter(null);
    if (regionRange !== prevRegionRange) {
      startTransition(() => {
        updateFilter({ regionRange });
      });
      trackWithActivityName('click_change_filter_range', { range: regionRange });
    }
  };

  const handleClickRefresh = () => {
    trackWithActivityName('click_reset_filter_range');
    setRegionRange(defaultFilterState.regionRange);
  };

  const handleClickRegionRange = () => {
    stepPop();
    push('region_range', {
      regionId: String(region?.id ?? ''),
      selectedRegionRange: String(regionRange ?? defaultFilterState.regionRange) as
        | '1'
        | '2'
        | '3'
        | '4'
        | '5',
    });
  };

  useEffect(() => {
    if (openedFilter === 'distance') {
      setRegionRange(prevRegionRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedFilter]);

  return (
    <Container>
      <Wrapper>
        <TextButton
          UNSAFE_style={{ padding: 0 }}
          marginBottom={rem(20)}
          onClick={handleClickRegionRange}
          size="medium"
          style="underlined"
          variant="secondary"
        >
          {`${regionName} 외 근처 동네 ${
            numOfNeighborhoods?.[regionRange ?? defaultFilterState.regionRange] ?? 0
          }개`}
        </TextButton>
        <Slider
          markers={markers}
          maxValue={Math.max(...regionRangeList)}
          minValue={Math.min(...regionRangeList)}
          onChange={setRegionRange}
          value={regionRange ?? defaultFilterState.regionRange}
        />
      </Wrapper>
      <FilterButtonFooter
        isLoading={isPending}
        onClickConfirm={handleClickSave}
        onClickRefresh={handleClickRefresh}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(4)} ${rem(16)} ${rem(24)};
`;

export default DistanceFilter;
