import { useCallback } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';

import {
  CreateNewArticleNotificationConditionInput,
  useCreateNotification_CreateNewArticleNotificationConditionMutation,
  useCreateNotification_CreateNewArticleNotificationConditionMutation$data,
} from '@/__generated__/useCreateNotification_CreateNewArticleNotificationConditionMutation.graphql';
import { useCreateNotificationQuery } from '@/__generated__/useCreateNotificationQuery.graphql';
import { MAX_NOTIFICATION_COUNT } from '@/constants/notification';
import { useNotificationContext } from '@/contexts/NotificationContext';
import { useToast } from '@/hooks/useToast';
import getDataID from '@/relay/getDataID';
import { useFlow } from '@/stackflow';
import { pickFirstError } from '@/utils/relay';
import { StorageKey, carStorage } from '@/utils/storage';

import useTrack from './useTrack';

const useCreateNotification = () => {
  const { push } = useFlow();
  const { setToast } = useToast();
  const { trackWithActivityName } = useTrack();
  const { openNotificationBottomSheet } = useNotificationContext();

  const { conditions } = useLazyLoadQuery<useCreateNotificationQuery>(
    graphql`
      query useCreateNotificationQuery {
        conditions: newArticleNotificationConditions {
          id
        }
      }
    `,
    {}
  );

  const [createNotification, isInFlight] =
    useMutation<useCreateNotification_CreateNewArticleNotificationConditionMutation>(graphql`
      mutation useCreateNotification_CreateNewArticleNotificationConditionMutation(
        $input: CreateNewArticleNotificationConditionInput!
      ) {
        newCondition: createNewArticleNotificationCondition(input: $input) {
          id
          ...NotificationItem_newArticleNotificationCondition
        }
      }
    `);

  const create = useCallback(
    (input: Omit<CreateNewArticleNotificationConditionInput, 'regionRange'>) =>
      new Promise<
        useCreateNotification_CreateNewArticleNotificationConditionMutation$data['newCondition']
      >((resolve, reject) => {
        if (isInFlight) {
          return reject(new Error('이미 요청이 진행 중입니다.'));
        }
        createNotification({
          variables: { input: { ...input, regionRange: 5 } },
          updater(store, data) {
            if (!data) {
              return;
            }
            const { newCondition } = data;
            const root = store.getRoot();
            const records = root.getLinkedRecords('newArticleNotificationConditions');
            const dataID = getDataID(newCondition, 'NewArticleNotificationCondition');
            const record = store.get(dataID);

            if (!record) {
              return;
            }
            root.setLinkedRecords([...(records ?? []), record], 'newArticleNotificationConditions');
          },
          onCompleted({ newCondition }) {
            resolve(newCondition);
          },
          onError(error) {
            reject(error);
          },
        });
      }),
    [createNotification, isInFlight]
  );

  const handleClickCreate = useCallback(
    async (input: Omit<CreateNewArticleNotificationConditionInput, 'regionRange'>) => {
      try {
        await create(input);

        const nextCount = (conditions ?? []).length + 1;
        const hasNotifiedNotificationMax = carStorage.getItem(StorageKey.NotificationMaxReached);

        if (nextCount >= MAX_NOTIFICATION_COUNT && !hasNotifiedNotificationMax) {
          openNotificationBottomSheet();
          return;
        }
        const answer = await setToast({
          message: `알림이 설정되었어요 (${nextCount}/${MAX_NOTIFICATION_COUNT})`,
          actionLabel: '알림 관리',
          type: 'success',
        });
        if (answer) {
          push('notification', {});
          trackWithActivityName('click_move_to_notification_toast');
        }
      } catch (e: any) {
        const error = pickFirstError(e);

        error?.message && setToast({ message: error.message });
      }
    },
    [conditions, create, openNotificationBottomSheet, push, setToast, trackWithActivityName]
  );

  return { create, handleClickCreate, isInFlight };
};

export default useCreateNotification;
