import { useEffect, useState } from 'react';

function useVisibilityState(): DocumentVisibilityState {
  const [visibilityState, setVisibilityState] = useState<DocumentVisibilityState>(
    document.visibilityState
  );

  const handleVisibilityChange = () => {
    setVisibilityState(document.visibilityState);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visibilityState;
}

export default useVisibilityState;
