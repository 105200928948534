import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';

import Scheme from '@/constants/scheme';
import useRedirectAppScheme from '@/hooks/useRedirectAppScheme';
import { karrotBridge } from '@/sdk/bridge';
import { userState } from '@/store/user';

import useUser from './useUser';
import useVisibilityState from './useVisibilityState';

const useUpdatedCheckInState = () => {
  const [isCheckInOpened, setCheckInOpened] = useState(false);
  const { isCheckedIn } = useUser();
  const redirect = useRedirectAppScheme();
  const refreshUserState = useSetAtom(userState);
  const prevVisibilityStateRef = useRef<DocumentVisibilityState | undefined>();
  const resultRef = useRef<((value: PromiseLike<boolean> | boolean) => void) | undefined>(
    undefined
  );

  const visibilityState = useVisibilityState();

  const getUpdatedCheckInState = async () => {
    if (isCheckedIn) {
      return true;
    }
    redirect(Scheme.Checkin);
    setCheckInOpened(true);

    return new Promise<boolean>((resolve) => {
      resultRef.current = resolve;
    });
  };
  useEffect(() => {
    const check = async () => {
      if (
        prevVisibilityStateRef.current === 'hidden' &&
        visibilityState === 'visible' &&
        isCheckInOpened
      ) {
        const userInfo = await karrotBridge.getUserInfo({});
        const nextCheckInCount = userInfo?.info?.user?.displayRegionCheckinsCount ?? 0;

        if (nextCheckInCount > 0) {
          refreshUserState();
          resultRef.current?.(true);
        } else {
          resultRef.current?.(false);
        }
        setCheckInOpened(false);
      }
    };
    check();

    return () => {
      prevVisibilityStateRef.current = visibilityState;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibilityState]);

  return getUpdatedCheckInState;
};

export default useUpdatedCheckInState;
