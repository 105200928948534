import styled from '@emotion/styled';
import React, { memo } from 'react';

import { FilterList_region$key } from '@/__generated__/FilterList_region.graphql';
import HStack from '@/components/Base/HStack';
import FilterList from '@/components/Filter/FilterList';

type Props = {
  regionRef?: FilterList_region$key | null;
};

const FilterHeader: React.FC<React.PropsWithChildren<Props>> = ({ regionRef, ...props }) => {
  return (
    <Container
      {...props}
      align="center"
      bg="paperDefault"
      pb={8}
      pos="sticky"
      pt={12}
      w="100%"
      z={1}
    >
      <FilterList regionRef={regionRef} />
    </Container>
  );
};

const Container = styled(HStack)({
  top: 0,
});

export default memo(FilterHeader);
