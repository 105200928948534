/**
 * @generated SignedSource<<27c2de3a1725e510daef0697c8db21df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterLayerQuery$variables = {
  regionId: string;
  skip: boolean;
};
export type FilterLayerQuery$data = {
  readonly region?: {
    readonly " $fragmentSpreads": FragmentRefs<"DistanceFilter_region">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyIdsFilter_autoupCompanies">;
};
export type FilterLayerQuery = {
  response: FilterLayerQuery$data;
  variables: FilterLayerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "regionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "regionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilterLayerQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DistanceFilter_region"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CompanyIdsFilter_autoupCompanies"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilterLayerQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Region",
            "kind": "LinkedField",
            "name": "region",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numOfNeighborhoods",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutoupCompany",
        "kind": "LinkedField",
        "name": "autoupCompanies",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyOrigin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imgUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b5f755962650c1085e7727b3e8f19f3e",
    "id": null,
    "metadata": {},
    "name": "FilterLayerQuery",
    "operationKind": "query",
    "text": "query FilterLayerQuery(\n  $regionId: String!\n  $skip: Boolean!\n) {\n  region(id: $regionId) @skip(if: $skip) {\n    ...DistanceFilter_region\n    id\n  }\n  ...CompanyIdsFilter_autoupCompanies\n}\n\nfragment CompanyIcon_autoupCompany on AutoupCompany {\n  imgUrl\n}\n\nfragment CompanyIdsFilter_autoupCompanies on Query {\n  autoupCompanies {\n    id\n    name\n    count\n    companyOrigin\n    ...CompanyIcon_autoupCompany\n  }\n}\n\nfragment DistanceFilter_region on Region {\n  numOfNeighborhoods\n}\n"
  }
};
})();

(node as any).hash = "351dac2effb6e605ca0999133e867131";

export default node;
