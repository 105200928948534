/**
 * @generated SignedSource<<9e7b82c785dce15ae0bea9f66a13dc46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type CompanyOrigin = "FOREIGN" | "KOREAN";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Gear = "AUTO" | "CVT" | "MANUAL" | "SEMI_AUTO" | "UNKNOWN";
export type SaleType = "GENERAL" | "LEASE" | "RENTAL";
import { FragmentRefs } from "relay-runtime";
export type notification_newArticleNotificationCondition$data = {
  readonly carTypes: ReadonlyArray<CarType> | null | undefined;
  readonly companies: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly companyOrigin: CompanyOrigin | null | undefined;
  readonly driveDistanceMax: number | null | undefined;
  readonly driveDistanceMin: number | null | undefined;
  readonly fuelTypes: ReadonlyArray<FuelType> | null | undefined;
  readonly gears: ReadonlyArray<Gear> | null | undefined;
  readonly id: string;
  readonly modelYearMax: number | null | undefined;
  readonly modelYearMin: number | null | undefined;
  readonly priceMax: number | null | undefined;
  readonly priceMin: number | null | undefined;
  readonly saleTypes: ReadonlyArray<SaleType> | null | undefined;
  readonly series: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly subseries: ReadonlyArray<{
    readonly id: string;
  }> | null | undefined;
  readonly " $fragmentType": "notification_newArticleNotificationCondition";
};
export type notification_newArticleNotificationCondition$key = {
  readonly " $data"?: notification_newArticleNotificationCondition$data;
  readonly " $fragmentSpreads": FragmentRefs<"notification_newArticleNotificationCondition">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "notification_newArticleNotificationCondition"
};

(node as any).hash = "8f36a0846f36e35c4806a80c381ba0a3";

export default node;
