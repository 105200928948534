import { isEqual, noop } from 'lodash-es';
import React, {
  type TransitionStartFunction,
  createContext,
  memo,
  useCallback,
  useContext,
  useState,
  useTransition,
} from 'react';

import { useEventCallback } from '@/hooks/useEventCallback';
import { ActivityName, useStepFlow } from '@/stackflow';
import { CarType } from '@/types/CarData/CarType';
import { CompanyOrigin } from '@/types/CarData/CompanyOrigin';
import { FuelType } from '@/types/CarData/FuelType';
import { GearType } from '@/types/CarData/GearType';
import { SaleType } from '@/types/CarData/SaleType';
import { nonNullish } from '@/utils/typeGuard';
import { useCamelCaseParams } from '@/utils/url';

type WarrantyScope = 'INSPECTED' | 'NONE' | 'WARRANTABLE';
export interface FilterState {
  carTypes: CarType[] | null;
  companyIds?: null | string[];
  companyOrigin: CompanyOrigin | null;
  driveDistanceMax: null | number;
  driveDistanceMin: null | number;
  fuelTypes: FuelType[] | null;
  gears: GearType[] | null;
  modelYearMax: null | number;
  modelYearMin: null | number;
  onlyOnSale: boolean;
  priceMax: null | number;
  priceMin: null | number;
  regionRange: null | number;
  saleTypes: SaleType[] | null;
  warrantyScope: WarrantyScope | null;
}

export const defaultFilterState: {
  regionRange: NonNullable<FilterState['regionRange']>;
} & FilterState = {
  carTypes: null,
  fuelTypes: null,
  saleTypes: null,
  gears: null,
  companyOrigin: null,
  modelYearMin: null,
  modelYearMax: null,
  driveDistanceMin: null,
  driveDistanceMax: null,
  priceMin: null,
  priceMax: null,
  regionRange: 5,
  onlyOnSale: true,
  companyIds: null,
  warrantyScope: null,
};

export type FilterField = 'sort' | keyof FilterState;

const defaultAllowedFilterFields: FilterField[] = ['companyOrigin'];

export type OpenedFilterState = 'companyIds' | 'distance' | 'options' | 'sort' | 'status' | null;

export type Sort = 'CHEAPEST' | 'LATEST';

export const SortText: Record<Sort, string> = {
  LATEST: '최신순',
  CHEAPEST: '낮은 가격순',
};

type FilterContextState = {
  allowedFilterFields: FilterField[];
  filter: FilterState;
  filterChangedCount: number;
  hasOptionsDiff: boolean;
  initialFilter?: Partial<FilterState>;
  isCompanyIdsActive: boolean;
  isDistanceActive: boolean;
  isOptionsActive: boolean;
  isPending: boolean;
  isStatusActive: boolean;
  isWarrantyActive: boolean;
  openedFilter: OpenedFilterState;
  sort: Sort;
};

const FilterContext = createContext<FilterContextState>({
  openedFilter: null,
  filter: { ...defaultFilterState },
  sort: 'LATEST',
  isDistanceActive: false,
  isOptionsActive: false,
  isStatusActive: false,
  isWarrantyActive: false,
  hasOptionsDiff: false,
  allowedFilterFields: [...defaultAllowedFilterFields],
  isCompanyIdsActive: false,
  filterChangedCount: 0,
  isPending: false,
});

export const useFilterContext = () => {
  return useContext(FilterContext);
};

type ReactStateUpdater<T> = React.Dispatch<React.SetStateAction<T>>;

type FilterUpdaterContextState = {
  resetFilter: () => void;
  setFilterChangedCount: ReactStateUpdater<number>;
  startTransition: TransitionStartFunction;
  updateFilter: ReactStateUpdater<FilterState>;
  updateOpenedFilter: (nextOpenedFilter: OpenedFilterState) => void;
  updateSort: ReactStateUpdater<Sort>;
};

const FilterUpdaterContext = createContext<FilterUpdaterContextState>({
  updateOpenedFilter: noop,
  updateFilter: noop,
  updateSort: noop,
  setFilterChangedCount: noop,
  resetFilter: noop,
  startTransition: noop,
});

export const useFilterUpdaterContext = () => {
  return useContext(FilterUpdaterContext);
};

type Props = {
  activityName: ActivityName;
  allowedFilterFields?: FilterField[];
  initialFilter?: Partial<FilterState>;
  initialFilterOnce?: Partial<FilterState>;
};

const FilterContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  activityName,
  initialFilter,
  initialFilterOnce,
  allowedFilterFields = defaultAllowedFilterFields,
}) => {
  const { stepPush, stepReplace, stepPop } = useStepFlow(activityName);
  const { state: openedFilter = null, ...restQueryParams } = useCamelCaseParams<{
    state?: OpenedFilterState;
  }>();
  const [isPending, startTransition] = useTransition();

  const [sort, setSort] = useState<Sort>('LATEST');
  const [filterChangedCount, setFilterChangedCount] = useState(0);
  const [filter, setFilter] = useState<FilterState>({
    ...defaultFilterState,
    ...initialFilterOnce,
    ...initialFilter,
  });

  const {
    carTypes,
    fuelTypes,
    saleTypes,
    gears,
    companyOrigin,
    regionRange,
    onlyOnSale,
    companyIds,
    warrantyScope,
    ...restFilter
  } = filter;

  const hasDiff = useCallback((origin: string[], fixed: string[]) => {
    return !isEqual(origin, fixed);
  }, []);

  const isDistanceActive = regionRange !== defaultFilterState.regionRange;
  const isOptionsActive =
    (carTypes ?? []).length > 0 ||
    (fuelTypes ?? []).length > 0 ||
    (saleTypes ?? []).length > 0 ||
    (gears ?? []).length > 0 ||
    !!companyOrigin ||
    Object.entries(restFilter).some(([, v]) => v !== null);

  const hasOptionsDiff =
    hasDiff(carTypes ?? [], initialFilter?.carTypes ?? []) ||
    hasDiff(fuelTypes ?? [], initialFilter?.fuelTypes ?? []) ||
    hasDiff(saleTypes ?? [], initialFilter?.saleTypes ?? []) ||
    hasDiff(gears ?? [], initialFilter?.gears ?? []) ||
    hasDiff(
      [companyOrigin].filter(nonNullish),
      [initialFilter?.companyOrigin].filter(nonNullish)
    ) ||
    Object.entries(restFilter).some(([, v]) => v !== null);

  const isStatusActive = onlyOnSale !== defaultFilterState.onlyOnSale;
  const isCompanyIdsActive = (companyIds?.length ?? 0) > 0;
  const isWarrantyActive = warrantyScope === 'WARRANTABLE';

  const resetFilter = useEventCallback(() => {
    setFilter({
      ...defaultFilterState,
      ...initialFilter,
    });
  });

  const updateOpenedFilter = useEventCallback((nextOpenedFilter: OpenedFilterState) => {
    if (!openedFilter) {
      stepPush({ ...restQueryParams, state: nextOpenedFilter });
    } else if (nextOpenedFilter == null) {
      stepPop();
    } else {
      stepReplace({ ...restQueryParams, state: nextOpenedFilter });
    }
  });

  return (
    <FilterContext.Provider
      value={{
        isPending,
        openedFilter,
        filter,
        sort,
        isDistanceActive,
        isOptionsActive,
        isStatusActive,
        isWarrantyActive,
        allowedFilterFields,
        isCompanyIdsActive,
        filterChangedCount,
        initialFilter,
        hasOptionsDiff,
      }}
    >
      <FilterUpdaterContext.Provider
        value={{
          updateOpenedFilter,
          updateFilter: setFilter,
          updateSort: setSort,
          resetFilter,
          setFilterChangedCount,
          startTransition,
        }}
      >
        {children}
      </FilterUpdaterContext.Provider>
    </FilterContext.Provider>
  );
};

export default memo(FilterContextProvider);
