import styled from '@emotion/styled';
import { useCallbackOnce } from '@toss/react';
import React, { useEffect, useMemo, useRef } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';

import { MainQueryContainerQuery } from '@/__generated__/MainQueryContainerQuery.graphql';
import Box from '@/components/Base/Box';
import { Title3Bold } from '@/components/Base/Text';
import { useFilterContext } from '@/components/Filter/FilterContextProvider';
import useNavigatorHeight from '@/hooks/useNavigatorHeight';
import { useScrollPassState } from '@/hooks/useScrollPass';
import useTrack from '@/hooks/useTrack';
import useUser from '@/hooks/useUser';
import MainPv from '@/pages/Main/components/MainPv';
import { scrollToTop } from '@/utils/scroll';

import ArticleFeedQueryContainer from './ArticleFeedQueryContainer';
import Home2QueryContainer from './Home2QueryContainer';

const MainQueryContainer: React.FC<React.PropsWithChildren<unknown>> = ({}) => {
  const feedContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollCheckerRef = useRef<HTMLDivElement>(null);
  const navigatorHeight = useNavigatorHeight();
  const { track } = useTrack();
  const { filter, filterChangedCount } = useFilterContext();
  const { region } = useUser();
  const stickySearchBarSize = 44;
  const isScrollPassedHome = useScrollPassState({
    scrollContainerRef,
    targetRef: scrollCheckerRef,
  });

  const syncScroll = () => {
    const scrollContainerScrollTop = scrollContainerRef.current?.scrollTop;
    const feedContainerOffsetTop = feedContainerRef.current?.offsetTop;
    if (!scrollContainerScrollTop || !feedContainerOffsetTop || !filterChangedCount) {
      return;
    }
    if (scrollContainerScrollTop > feedContainerOffsetTop) {
      if (scrollContainerRef.current) {
        scrollToTop(scrollContainerRef.current, {
          behavior: 'auto',
          top: feedContainerOffsetTop - navigatorHeight - stickySearchBarSize,
        });
      }
    }
  };

  const trackFeedImpression = useCallbackOnce(() => {
    track('main_impression_feed', {});
  }, [track]);

  useEffect(() => {
    if (isScrollPassedHome) {
      trackFeedImpression();
    }
  }, [isScrollPassedHome, trackFeedImpression]);

  const queryParams = useMemo(
    () => ({
      regionId: `${region?.id}`,
      skip: !region?.id,
      count: 20,
      filter,
    }),
    [filter, region?.id]
  );
  const query = useLazyLoadQuery<MainQueryContainerQuery>(
    graphql`
      query MainQueryContainerQuery(
        $regionId: String!
        $skip: Boolean!
        $filter: ArticleFilterInput
        $count: Int!
      ) {
        region(id: $regionId) @skip(if: $skip) {
          ...FilterList_region
        }
        ...ArticleFeedQueryContainer_query @arguments(filter: $filter)
        ...CreateNotificationButton_query
        ...MainContentsSection_query
        ...CarouselSection_query @arguments(count: $count)
      }
    `,
    queryParams
  );

  return (
    <ContentWrapper ref={scrollContainerRef}>
      <Home2QueryContainer query={query} />
      <ScrollChecker ref={scrollCheckerRef} />
      <Box px={16}>
        <Title3Bold>딜러 수수료 없는 당근 중고차</Title3Bold>
      </Box>
      <ArticleFeedQueryContainer
        filterHeaderTopMargin={stickySearchBarSize}
        isScrollReached={isScrollPassedHome}
        onAfterFilterChanged={syncScroll}
        query={query}
        ref={feedContainerRef}
      />
      <MainPv />
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ScrollChecker = styled.div({
  width: '100%',
  height: '1px',
});

export default MainQueryContainer;
