import { isEqual } from 'lodash-es';
import { graphql, readInlineData } from 'relay-runtime';

import type { NotificationCondition } from '@/types/Notification/NotificationCondition';

import { notification_newArticleNotificationCondition$key } from '@/__generated__/notification_newArticleNotificationCondition.graphql';

const autoupFieldKeys = [
  ['companies', 'companyIds'],
  ['series', 'seriesIds'],
  ['subseries', 'subseriesIds'],
] as const;
const arrayFieldKeys = ['carTypes', 'fuelTypes', 'saleTypes', 'gears'] as const;
const otherFieldKeys = [
  'modelYearMin',
  'modelYearMax',
  'driveDistanceMin',
  'driveDistanceMax',
  'priceMin',
  'priceMax',
  'companyOrigin',
] as const;

const sortString = (s1: string, s2: string) => {
  if (s1 > s2) {
    return 1;
  }
  if (s1 < s2) {
    return -1;
  }
  return 0;
};

export const isSameNotificationCondition = (
  newArticleNotificationConditionRef: notification_newArticleNotificationCondition$key,
  condition: NotificationCondition
) => {
  const existingCondition = readInlineData(
    graphql`
      fragment notification_newArticleNotificationCondition on NewArticleNotificationCondition
      @inline {
        id
        companies {
          id
        }
        series {
          id
        }
        subseries {
          id
        }
        carTypes
        fuelTypes
        saleTypes
        gears
        companyOrigin
        modelYearMin
        modelYearMax
        driveDistanceMin
        driveDistanceMax
        priceMin
        priceMax
      }
    `,
    newArticleNotificationConditionRef
  );

  const isAutoupEqual = autoupFieldKeys.every(([queryKey, key]) => {
    const existingValue = [...(existingCondition[queryKey] ?? [])]
      .map(({ id }) => id)
      .sort(sortString);
    const value = [...(condition[key] ?? [])].sort(sortString);

    return isEqual(existingValue, value);
  });

  const isArrayFieldEqual = arrayFieldKeys.every((key) => {
    const existingValue = [...(existingCondition[key] ?? [])].sort(sortString);
    const value = [...(condition[key] ?? [])].sort(sortString);

    return isEqual(existingValue, value);
  });

  const isOtherFieldEqual = otherFieldKeys.every((key) =>
    isEqual(existingCondition[key], condition[key])
  );

  return isAutoupEqual && isArrayFieldEqual && isOtherFieldEqual;
};
