import { BoxButton } from '@daangn/sprout-components-button';
import { IconSearchDocFill } from '@seed-design/icon';
import React, { useCallback } from 'react';

import { getSeedColor } from '@/components/Base/Box';
import Center from '@/components/Base/Center';
import { BodyL1Regular } from '@/components/Base/Text';
import VStack from '@/components/Base/VStack';
import { useFilterUpdaterContext } from '@/components/Filter/FilterContextProvider';
import { getSizeProps } from '@/utils/style';

const NoMatchedArticles: React.FC = () => {
  const { resetFilter, startTransition } = useFilterUpdaterContext();
  const handleClickResetFilter = useCallback(() => {
    startTransition(() => {
      resetFilter();
    });
  }, [resetFilter, startTransition]);

  return (
    <Center gap={16} px={16} py={32}>
      <VStack align="center" gap={10}>
        <IconSearchDocFill {...getSizeProps(48)} color={getSeedColor('gray400')} />
        <BodyL1Regular color="gray600">현재 지역에 등록된 게시글이 없어요.</BodyL1Regular>
      </VStack>
      <BoxButton onClick={handleClickResetFilter} size="medium" variant="secondary">
        필터 초기화
      </BoxButton>
    </Center>
  );
};

export default NoMatchedArticles;
