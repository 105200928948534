/**
 * @generated SignedSource<<7144580d6627ecdc713c6c0cd09ea75d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DistanceFilter_region$data = {
  readonly numOfNeighborhoods: ReadonlyArray<number>;
  readonly " $fragmentType": "DistanceFilter_region";
};
export type DistanceFilter_region$key = {
  readonly " $data"?: DistanceFilter_region$data;
  readonly " $fragmentSpreads": FragmentRefs<"DistanceFilter_region">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DistanceFilter_region",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOfNeighborhoods",
      "storageKey": null
    }
  ],
  "type": "Region",
  "abstractKey": null
};

(node as any).hash = "d8dda96883512d88abdcaf7d6bfe41a2";

export default node;
