/**
 * @generated SignedSource<<0ca7ccaa349d827697aabfb35a53bc44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CarType = "BIG" | "BUSINESS" | "ETC" | "MIDDLE" | "SMALL" | "SUV";
export type CompanyOrigin = "FOREIGN" | "KOREAN";
export type FuelType = "CNG" | "DIESEL" | "DIESEL_ELECTRONIC" | "ELECTRONIC" | "FUEL_CELL_ELECTRONIC" | "GASOLINE" | "GASOLINE_ELECTRONIC" | "H2O_ELECTRONIC" | "HYBRID" | "LPG" | "LPG_ELECTRONIC" | "LPG_GASOLINE" | "UNKNOWN";
export type Gear = "AUTO" | "CVT" | "MANUAL" | "SEMI_AUTO" | "UNKNOWN";
export type SaleType = "GENERAL" | "LEASE" | "RENTAL";
export type WarrantyScope = "INSPECTED" | "NONE" | "WARRANTABLE";
export type CreateNewArticleNotificationConditionInput = {
  carTypes?: ReadonlyArray<CarType> | null | undefined;
  companyIds?: ReadonlyArray<string> | null | undefined;
  companyOrigin?: CompanyOrigin | null | undefined;
  driveDistanceMax?: number | null | undefined;
  driveDistanceMin?: number | null | undefined;
  fuelTypes?: ReadonlyArray<FuelType> | null | undefined;
  gears?: ReadonlyArray<Gear> | null | undefined;
  modelYearMax?: number | null | undefined;
  modelYearMin?: number | null | undefined;
  priceMax?: number | null | undefined;
  priceMin?: number | null | undefined;
  regionRange: number;
  saleTypes?: ReadonlyArray<SaleType> | null | undefined;
  seriesIds?: ReadonlyArray<string> | null | undefined;
  subseriesIds?: ReadonlyArray<string> | null | undefined;
  warrantyScope?: WarrantyScope | null | undefined;
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation$variables = {
  input: CreateNewArticleNotificationConditionInput;
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation$data = {
  readonly newCondition: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_newArticleNotificationCondition">;
  };
};
export type useCreateNotification_CreateNewArticleNotificationConditionMutation = {
  response: useCreateNotification_CreateNewArticleNotificationConditionMutation$data;
  variables: useCreateNotification_CreateNewArticleNotificationConditionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "selections": [
      {
        "alias": "newCondition",
        "args": (v1/*: any*/),
        "concreteType": "NewArticleNotificationCondition",
        "kind": "LinkedField",
        "name": "createNewArticleNotificationCondition",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationItem_newArticleNotificationCondition"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "selections": [
      {
        "alias": "newCondition",
        "args": (v1/*: any*/),
        "concreteType": "NewArticleNotificationCondition",
        "kind": "LinkedField",
        "name": "createNewArticleNotificationCondition",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupCompany",
            "kind": "LinkedField",
            "name": "companies",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupSeries",
            "kind": "LinkedField",
            "name": "series",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AutoupSubseries",
            "kind": "LinkedField",
            "name": "subseries",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "carTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fuelTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "saleTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gears",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modelYearMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modelYearMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "driveDistanceMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "driveDistanceMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priceMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "companyOrigin",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ddd3af424918884d7c05cfe8d0c10bd",
    "id": null,
    "metadata": {},
    "name": "useCreateNotification_CreateNewArticleNotificationConditionMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateNotification_CreateNewArticleNotificationConditionMutation(\n  $input: CreateNewArticleNotificationConditionInput!\n) {\n  newCondition: createNewArticleNotificationCondition(input: $input) {\n    id\n    ...NotificationItem_newArticleNotificationCondition\n  }\n}\n\nfragment NotificationItem_newArticleNotificationCondition on NewArticleNotificationCondition {\n  id\n  companies {\n    id\n    name\n  }\n  series {\n    id\n    name\n  }\n  subseries {\n    id\n    name\n  }\n  carTypes\n  fuelTypes\n  saleTypes\n  gears\n  modelYearMin\n  modelYearMax\n  driveDistanceMin\n  driveDistanceMax\n  priceMin\n  priceMax\n  companyOrigin\n}\n"
  }
};
})();

(node as any).hash = "c51716bff57f5f5301c0279d0cdbeb4b";

export default node;
