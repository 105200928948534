import { BoxButton } from '@daangn/sprout-components-button';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { IconNotificationFill } from '@seed-design/icon';
import { josa } from '@toss/hangul';
import { rem } from 'polished';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { NewArticleNotificationBottomSheet_subseries$key } from '@/__generated__/NewArticleNotificationBottomSheet_subseries.graphql';
import BottomSheet from '@/components/Base/BottomSheet/BottomSheet';
import useCreateNotification from '@/hooks/useCreateNotification';
import useTrack from '@/hooks/useTrack';
import { getSizeProps } from '@/utils/style';

type Props = {
  active: boolean;
  onClickClose: () => void;
  subseriesRef: NewArticleNotificationBottomSheet_subseries$key;
};

const NewArticleNotificationBottomSheet: React.FC<Props> = ({
  subseriesRef,
  active,
  onClickClose,
}) => {
  const { track } = useTrack();
  const subseries = useFragment(
    graphql`
      fragment NewArticleNotificationBottomSheet_subseries on AutoupSubseries {
        id
        name
        imgUrl
      }
    `,
    subseriesRef
  );
  const { id, name, imgUrl } = subseries;
  const { handleClickCreate: create } = useCreateNotification();

  const handleCreateFilter = async () => {
    const input = { subseriesIds: [id] };

    await create(input);
    onClickClose();
    track('article_click_create_new_article_notification', input);
  };

  return (
    <BottomSheet active={active} onClickClose={onClickClose} showCloseButton>
      <Wrapper>
        {imgUrl && <Image src={imgUrl} />}
        <Title>
          {josa(name, '이/가')} 등록되면
          <br />
          알려드릴게요
        </Title>
        <ButtonWrapper>
          <BoxButton onClick={handleCreateFilter} size="large" variant="primary" width="100%">
            <ButtonContentWrapper>
              <IconNotification {...getSizeProps(20)} color={vars.$semantic.color.onPrimary} />
              알림 받기
            </ButtonContentWrapper>
          </BoxButton>
        </ButtonWrapper>
      </Wrapper>
    </BottomSheet>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: ${rem(108)};
  height: ${rem(64.8)};
`;

const Title = styled.p`
  text-align: center;
  margin-top: ${rem(16)};
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title2Bold};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${rem(16)};
  padding: ${rem(12)} ${rem(16)} ${rem(8)} ${rem(16)};
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${vars.$semantic.color.onPrimary};
  ${vars.$semantic.typography.label1Bold};
`;

const IconNotification = styled(IconNotificationFill)`
  margin-right: ${rem(4)};
`;

export default NewArticleNotificationBottomSheet;
